<template>
  <div class="detail-index-bg">
    <div class="detail-index" v-loading="pageLoading">
      <div class="detail-index-top">
        <div class="detail-index-top-content-headPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-content-footerPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-left">
          <img style="width: 100%; height: 100%" :src="info.pic" alt="" />
        </div>
        <div class="detail-index-top-right">
          <div class="map-detail-top-title">{{ info.name }}</div>
          <div class="maplist-detail-top-des">
            <ul class="maplist-detail-top-des-ul">
              <li class="maplist-detail-top-des-ul-li" v-if="info.place">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_place_maplist.png"
                  alt=""
                />
                <div class="maplist-detail-top-des-ul-li-div">
                  <span>场所：</span>{{ info.place }}
                </div>
              </li>

              <li class="maplist-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_time_resource_t.png"
                  alt=""
                />
                <div class="maplist-detail-top-des-ul-li-div">
                  <span>时间：</span>{{ info.work_time }}
                </div>
              </li>
              <li class="maplist-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_address_icon.png"
                  alt=""
                />
                <div class="maplist-detail-top-des-ul-li-div">
                  <span>地址：</span>{{ info.address }}
                </div>
              </li>
              <li class="maplist-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_phone_icon.png"
                  alt=""
                />
                <div class="maplist-detail-top-des-ul-li-div">
                  <span>电话：</span>{{ info.phone }}
                </div>
              </li>
            </ul>
          </div>
          <div
            class="maplist-detail-top-button"
            v-if="info.available_reserve == 1"
            @click="handleServe"
          >
            场馆预定
          </div>
        </div>
      </div>
      <div class="detail-index-bottom">
        <div class="detail-index-bottom-content">
          <div class="detail-index-top-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="detail-index-bottom-content-top">
            <div class="detail-index-bottom-content-top-title">场馆详情</div>
          </div>

          <div class="detail-index-bottom-content-bottom">
            <div class="detail-index-bottom-content-bottom-box">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  简介
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div v-html="info.des">{{ info.des }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mydialog
      v-if="formDialogShow"
      :keyword="'扫码预定场馆'"
      :isShow="formDialogShow"
      :src="detailSrc"
      @close="formDialogShow = false"
    />
  </div>
</template>

<script>
import { venueInfo, getVenue } from "../../api/map/map.js";
import { genQRCode } from "@/api/common";
import Cdetail from "../components/Cdetail";
import mydialog from "@/components/myDialog";
import {
  queryResourceLike,
  queryResourceShare,
  queryResourceUp,
} from "@/api/common";
import axios from "axios";
export default {
  components: { Cdetail, mydialog },
  data() {
    return {
      pageLoading: true,
      shareCount: 0,
      upCount: 0,
      likeCount: 0,
      id: null,
      info: {},
      formDialogShow: false,
      detailType: "map_detail",
      map_team_list: [],
      detailSrc: "",
    };
  },
  created() {
    let id = this.$route.query.id;
    if (!id) {
      this.$router.push({ path: "/" });
    }
    this.id = id;
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.getDetail();
  },
  watch: {
    $route: function(newVal) {
      // console.log(newVal,oldVal);
      this.id = newVal.query.id;
      this.getDetail();
    },
  },
  methods: {
    getDetail() {
      let self = this;
      venueInfo({ id: this.id, aid: process.env.VUE_APP_AID }).then((res) => {
        if (res.code == 0) {
          const regex = new RegExp("<img", "gi");
          res.data.des = res.data.des.replace(
            regex,
            `<img style="max-width: 100%; height: auto;margin:0 auto"`
          );
          this.info = res.data;

          let params = {
            aid: process.env.VUE_APP_AID,
            resource_type: "stadium",
            resource_id: this.id,
          };

          axios
            .all([
              queryResourceLike(params),
              queryResourceShare(params),
              queryResourceUp(params),
            ])
            .then(
              axios.spread(function(a, b, c) {
                self.likeCount = a.data;
                self.shareCount = b.data;
                self.upCount = c.data;
              })
            );
        }
        self.pageLoading = false;
      });
    },
    handleServe() {
      let params = {
        wxapp_name: "culture",
        page:
          "bookgo/culture/activity/detail/detail?id=" +
          this.id +
          "&aid=" +
          process.env.VUE_APP_AID,
        scene: "",
        aid:process.env.VUE_APP_AID
      };
      this.formDialogShow = true;
      genQRCode(params).then((res) => {
        let pic = res.data;
        this.detailSrc = pic;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
@import "../../assets/scss/map_detail.scss";
</style>
